<template>
  <div id="loading-spinner">
    <div id="spinner-front" :class="showHide">
      <img src="../assets/805.svg"/>
    </div>
    <div id="spinner-back" :class="showHide"></div>
  </div>
</template>

<script>
export default {
  name: 'loading-spinner',
  props: {
    showHide: String
  }
}
</script>

<style lang="scss" scoped>
#spinner-front, #spinner-back {
  position: fixed;
  top: 0;
  left: 0;
  visibility: hidden;
  opacity: 0;
  width: 100%;
  transition: opacity 1s;
}

#spinner-front {
  z-index: 999;
  margin-top: 45vh;
  color: #fff;
  text-align: center;

  &.show {
    visibility: visible;
    opacity: 1;
  }
}
#spinner-back {
  z-index: 998;
  height: 100vh;
  background: #000;

  &.show {
    visibility: visible;
    opacity: 0.7;
  }
}
</style>
