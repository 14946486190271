<template>
  <div id="certificates">
    <LoadingSpinner :showHide="showHide" />

    <PageHeader heading="Certificates" :subHeading="`(Using ${totalCertCount} out of ${totalCertsAllowed}.)`" />

    <b-row class="mt-2">
      <b-col>
        <CustomCertDns />
      </b-col>
    </b-row>

    <b-row class="mt-2">
      <b-col cols="6">
        <b-form inline>
          <b-input-group size="sm" prepend="Search by included domain name...">
            <b-form-input v-model="search"></b-form-input>
          </b-input-group>
          <b-form-checkbox v-model="filterDomainList" class="mb-2 ml-sm-2 mb-sm-0">Also filter domain list</b-form-checkbox>
        </b-form>
      </b-col>
    </b-row>

    <b-row v-if="e" class="mt-2">
      <b-col>
        <ErrorMessage>{{ e }}</ErrorMessage>
      </b-col>
    </b-row>

    <b-row class="mt-2">
      <b-col>
        <b-table striped hover bordered :items="tableValues" :fields="fields">

          <template v-slot:cell(actions)="row">
            <b-button size="sm" variant="danger" @click="deleteAction(row.item.id)">Delete</b-button>
          </template>

          <template v-slot:cell(domains)="data">
            <b-list-group
              horizontal
              class="flex-wrap"
            >
              <b-list-group-item
                v-for="d in data.item.domains"
                :key="d"
                class="mr-1"
              >
                {{ d }}
              </b-list-group-item>
            </b-list-group>
          </template>

        </b-table>
      </b-col>
    </b-row>

    <b-row class="mt-2" v-b-visible="visibleHandler" v-if="nextPageUrl !== null">
      <b-col>
        <p>something to trigger v-b-visible</p>
      </b-col>
    </b-row>

  </div>
</template>

<script>
import PageHeader from '@/components/PageHeader'
import CertApi from '@/services/api/Certificates'
import ErrorMessage from '@/components/ErrorMessage.vue'
import LoadingSpinner from '@/components/LoadingSpinner.vue'
import CustomCertDns from '@/components/CustomCertDns.vue'

export default {
  name: 'certificates',
  components: {
    PageHeader,
    ErrorMessage,
    LoadingSpinner,
    CustomCertDns,
  },
  data() {
    return {
      certificates: [],
      loading: true,
      e: null,
      search: '',
      fields: [
        { key: 'id', label: 'ID', sortable: true },
        { key: 'domains', label: 'Domain(s)' },
        { key: 'expires', label: 'Expires On', sortable: true },
        { key: 'actions', label: 'Actions' },
      ],
      filterDomainList: true,
      totalCertCount: 0,
      totalCertsAllowed: 1500,
      nextPageUrl: null,
    }
  },
  computed: {
    filteredCerts: function() {
      let self = this
      if (self.search) {
        return self.certificates.filter(c => {
          const domains = c.relationships.tls_domains.data;
          const filtered = domains.filter(d => d.id.indexOf(self.search) >= 0)
          return filtered.length > 0
        })
      } else {
        return self.certificates
      }
    },
    tableValues: function() {
      return this.filteredCerts.map(x => {
        const id = x.id
        const actions = ''
        let domains = x.relationships.tls_domains.data.map(d => d.id)
        if (this.filterDomainList && this.search) {
          domains = domains.filter(d => d.indexOf(this.search) >= 0)
        }
        const expires = x.attributes.not_after
        return {id, actions, expires, domains}
      })
    },
    showHide: function() {
      if (this.loading) {
        return 'show'
      } else {
        return 'hide'
      }
    }
  },
  async mounted() {
    try {
      const certResponse = await CertApi.getCerts()
      this.certificates = certResponse.data
      this.totalCertCount = certResponse.totalCount
      this.nextPageUrl = certResponse.nextPage
    }
    catch (e) {
      this.e = e
    }
    this.loading = false
  },
  methods: {
    deleteAction: async function(id) {
      const answer = await this.$bvModal.msgBoxConfirm(`Are you sure you want to delete the cert with ID ${id}? This action cannot be undone!`, {
        title: 'Confirm Delete',
        size: 'sm',
        okVariant: 'danger',
        okTitle: 'Yes',
        cancelTitle: 'No',
        centered: true
      })

      if (answer == true) {
        const resp = await CertApi.deleteCert(id)
        if (resp == 204) {
          this.certificates = this.certificates.filter(c => c.id != id)
          if (this.filteredCerts.length == 0 && this.search != '') {
            this.search = ''
          }
        }
      }
    },
    visibleHandler: async function(s) {
      if (s === true) {
        if (this.nextPageUrl !== null) {
          this.loading = true
          const resp = await CertApi.getCertsPaged(this.nextPageUrl)
          this.loading = false
          if (! resp.error) {
            this.certificates = [
              ...this.certificates,
              ...resp.data
            ]
            this.nextPageUrl = resp.nextPage
          }
        }
      }
    }
  }
}
</script>
