<template>
  <b-row class="mt-2">
    <b-col>
      <h1>
        {{ heading }}
        <small v-if="subHeading" class="text-muted">{{ subHeading }}</small>
      </h1>
      <slot></slot>
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: "page-header",
  props: {
    heading: String,
    subHeading: String,
  },
}
</script>
