<template>
  <div class="custom-cert-dns">
    <b-button v-b-toggle.custom-collapse>Show/Hide Custom DNS</b-button>
    <b-collapse visible id="custom-collapse" class="mt-1">
      <b-card>
        <b-row>
          <b-col cols="4">
            <b-card-text>
              <p><strong>CNAME</strong> (all subdomains):</p>
              <p><pre>advcdn-pantheon.map.fastly.net</pre></p>
            </b-card-text>
          </b-col>
          <b-col cols="4">
            <b-card-text>
              <p><strong>A</strong> (apex domains):</p>
              <p>
                <pre>151.101.2.228
151.101.66.228
151.101.130.228
151.101.194.228</pre>
              </p>
            </b-card-text>
          </b-col>
          <b-col cols="4">
            <b-card-text>
              <p><strong>AAAA</strong> (apex domains):</p>
              <p>
                <pre>2a04:4e42::740
2a04:4e42:200::740
2a04:4e42:400::740
2a04:4e42:600::740
2a04:4e42:800::740
2a04:4e42:a00::740
2a04:4e42:c00::740
2a04:4e42:e00::740</pre>
              </p>
            </b-card-text>
          </b-col>
        </b-row>
      </b-card>
    </b-collapse>
  </div>
</template>

<script>
export default {
  name: 'custom-cert-dns',
}
</script>

<style scoped>

</style>
