/* eslint-disable no-console */
import axios from 'axios'
import store from '@/store/index'
import { getInstance } from '@pantheon-systems/auth0-vue-module'

export default {
  async getCerts() {
    const token = await getInstance().getTokenSilently()
    try {
      const resp = await axios.post('/_api/certs', {
        apiKey: store.state.apiKey,
        method: 'get'
      }, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      return resp.data
    }
    catch (e) {
      if (e.response) {
        return e.response.data
      } else if (e.request) {
        return e.request
      } else {
        return e.message
      }
    }
  },

  async getCertsPaged(pagedUrl) {
    const token = await getInstance().getTokenSilently()
    try {
      const resp = await axios.post('/_api/certs-paged', {
        method: 'get',
        apiKey: store.state.apiKey,
        pagedUrl
      }, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      return resp.data
    } catch (e) {
      if (e.response) {
        return e.response.data
      } else if (e.request) {
        return e.request
      } else {
        return e.message
      }
    }
  },

  async uploadCert(cert, intermediate) {
    const token = await getInstance().getTokenSilently()
    const payload = {
      data: {
        type: "tls_bulk_certificate",
        attributes: {
          cert_blob: cert,
          intermediates_blob: intermediate
        },
        relationships: {
          tls_configurations: {
            data: [{
              type: "tls_configuration",
              id: process.env.VUE_APP_TLS_CONFIGURATION_ID
            }]
          }
        }
      }
    }

    try {
      const resp = await axios.post('/_api/certs', {
        apiKey: store.state.apiKey,
        method: 'post',
        payload
      }, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      return resp.data;
    }
    catch (e) {
      if (e.response) {
        return e.response.data
      } else if (e.request) {
        return e.request
      } else {
        return e.message
      }
    }
  },

  async deleteCert(id) {
    const token = await getInstance().getTokenSilently()
    try {
      const resp = await axios.post('/_api/certs', {
        apiKey: store.state.apiKey,
        method: 'delete',
        payload: id
      }, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      return resp.data.status
    }
    catch (e) {
      if (e.response) {
        return e.response.data
      } else if (e.request) {
        return e.request
      } else {
        return e.message
      }
    }
  }
}
