<template>
  <div class="error-message">
    <span class="message">{{ message }}</span>
  </div>
</template>

<script>
export default {
  name: 'error-message',
  props: {
    message: String
  }
}
</script>

<style scoped>
.message {
  background-color: red;
  color: white;
}
</style>